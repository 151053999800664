<template>
  <v-container>
    <v-row>
      <v-col
        md="6"
        offset-lg="3"
        offset-md="3"
        offset-sm="0"
        lg="6"
        sm="12"
        class=" text-center pa-5 d-flex flex-column justify-center "
      >
        <base-material-card
          color="primary"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light text-center">
              Ingresa al panel de proveedores
            </div>
          </template>
          <v-card-text>
            <v-img
              src="/images/logo-nodriza-negro.png"
              class="text-center"
              style="margin:auto"
              width="50%"
            >
            </v-img>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              autocomplete="false"
            >
              <v-text-field
                v-model="email"
                label="Email"
                :rules="rules.emailRules"
                required
                placeholder="Email"
                clearable
                outlined
                dense
              >
              </v-text-field>
              <v-text-field
                v-model="password"
                :type="showPassword ? 'text': 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                label="Contraseña"
                placeholder="Contraseña"
                :rules="rules.passwordRules"
                required
                outlined
                dense
              >
              </v-text-field>
              <div v-if="errors">
                <p class="error--text">
                  Usuario y/o contraseña incorrecta
                </p>
              </div>
              <v-btn
                color="primary"
                class="text-center"
                :disabled="!valid || dialog"
                @click="login()"
                @keyup.enter="login"
              >
                Ingresar
              </v-btn>
            </v-form>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="white--text">
          Cargando
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          >
          </v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
  import Vue from 'vue'
  import API from '@/api'
  import { Configuration } from '@/configuration'
  import { mapActions } from 'vuex'
  import Swal from 'sweetalert2'
  import { EventBus } from '@/main.js'

  export default {
    name: 'Login',
    title: 'Login - Onestock',
    components: {},
    data: () => ({
      tipoPanellogin: '',
      alert: false,
      dialog: false,
      errors: false,
      valid: false,
      email: '',
      showPassword: false,
      password: '',
      rules: {
        emailRules: [
          v => !!v || 'E-mail es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingrese un E-mail válido',
        ],
        passwordRules: [
          v => !!v || 'Contraseña es requerida',
        ],
      },
    }),
    computed: {
    },
    beforeMount () {
      this.tipoPanel()
    },
    methods: {
      tipoPanel () {
        if (this.$route.path === '/admin') {
          this.$data.tipoPanellogin = 'de administrador'
        }
        if (this.$route.path === '/clientes') {
          this.$data.tipoPanellogin = 'de cliente'
        }
        if (this.$route.path === '/proveedor') {
          this.$data.tipoPanellogin = 'de proveedor'
        }
      },
      ...mapActions(['RegisterToken', 'RegisterexpireDate', 'RegisterUser', 'RegisterTipeUser']),
      login () {
        this.$data.errors = false
        this.$data.dialog = true
        if (this.$refs.form.validate()) {
          API.loginProveedor(this.$data.email, this.$data.password)
            .then(res => {
              this.RegisterToken(res.data.respuesta.token)
              this.RegisterexpireDate(res.data.respuesta.expires_token)
              this.RegisterTipeUser('proveedor')
              API.verProveedor(res.data.respuesta.token, res.data.proveedor.id)
                .then((res) => {
                  this.RegisterUser(res.data.data)
                  this.$data.userData = res.data.data
                  this.$data.dialog = false
                  setTimeout(() => {
                    if (res.data.respuesta) {
                      EventBus.$emit('login', 'Haz iniciado sesión')
                    }
                    this.$router.push({
                      path: '/proveedor/editar-proveedor/',
                    })
                  }, Configuration.redirect.timeout)
                })
                // eslint-disable-next-line handle-callback-err
                .catch((err) => {
                  this.$data.dialog = false
                  this.$data.errors = true
                  Swal.fire({
                    icon: 'error',
                    title: 'Error al ingresar, revisen su usuario o contraseña',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
            })
            .catch(() => {
              this.$data.dialog = false
              this.$data.errors = true
              Swal.fire({
                icon: 'error',
                title: 'Error al ingresar, revisen su usuario o contraseña',
                showConfirmButton: false,
                timer: 1500,
              })
            })
        } else {
          this.$data.dialog = false
        }
      },
    },
  }
</script>
<style lang="sass" scoped>

</style>
